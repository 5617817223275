import React from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

type WidgetStepperProps = BoxProps & {
  activeStep: number,
};

export default function WidgetStepper({ activeStep, sx, ...rest }: WidgetStepperProps) {
  return (
    <Box
      {...rest}
      sx = {[
        {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography variant="body1">Шаг</Typography>

      <Stepper activeStep={activeStep} sx={{ flexGrow: 1 }}>
        <Step>
          <StepLabel></StepLabel>
        </Step>
        <Step>
          <StepLabel></StepLabel>
        </Step>
        <Step>
          <StepLabel></StepLabel>
        </Step>
        <Step>
          <StepLabel></StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
}
