const KEBAB_REGEX = /[A-Z]/g;

export function kebabCase(str: string): string {
  const result = str.replace(KEBAB_REGEX, (match) => `-${match.toLowerCase()}`);

  return result.startsWith('-') ? result.slice(1) : result;
}

const CAMEL_REGEX = /-[a-z]/g;

export function camelCase(str: string): string {
  return str.replace(CAMEL_REGEX, (match) => match.slice(1).toUpperCase())
}
