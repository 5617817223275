import React from 'react';

import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { SliderProps, SliderMarkLabel } from '@mui/material/Slider';

export type AligningMarkLabelProps = {
  'data-index': number,
  ownerState: SliderProps,
  sx?: SxProps,
}

declare module '@mui/material/Slider' {
  interface SliderComponentsPropsOverrides {
    sx: SxProps;
  }
}

export default function AligningMarkLabel(props: AligningMarkLabelProps) {
  const { 'data-index': index, ownerState: { marks }, sx } = props;

  const isFirst = index === 0;
  const isLast = Array.isArray(marks) && index === marks.length - 1;

  return (
    <SliderMarkLabel
      {...props}
      sx = {[
        isFirst && {
          transform: 'translateX(0)',
        },
        isLast && {
          transform: 'translateX(-100%)',
        },
        (!isFirst && !isLast) && ((theme: Theme) => ({
          display: 'none',
          [theme.containerQueries.up('sm')]: {
            display: 'block',
          },
        })),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
}
