import React, { forwardRef } from 'react';
import { IMaskInput, IMaskInputProps } from 'react-imask';

import TextField, { TextFieldProps, TextFieldVariants } from '@mui/material/TextField';

type IMaskProps = IMaskInputProps<HTMLInputElement> & {
  lazy?: boolean
}

const IMaskInputWrapper = forwardRef(function IMaskInputWrapper(props: IMaskProps, ref: React.ForwardedRef<HTMLInputElement>) {
  return (
    <IMaskInput {...props} inputRef={ref}/>
  );
});

type TopLevelRequiredProps = 'mask' | 'onAccept';
type TopLevelProps = 'lazy';

type MaskedTextFieldProps<Variant extends TextFieldVariants = TextFieldVariants> = TextFieldProps<Variant> &
  Pick<IMaskProps, TopLevelRequiredProps> &
  Partial<Pick<IMaskProps, TopLevelProps>> &
  {
    inputProps?: Omit<IMaskProps, TopLevelRequiredProps | TopLevelProps>,
  }

export default function MaskedTextField<Variant extends TextFieldVariants>({ mask, onAccept, lazy, inputProps, ...props }: MaskedTextFieldProps<Variant>) {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: IMaskInputWrapper,
        inputProps: {
          ...inputProps,
          lazy,
          mask,
          onAccept,
        },
      }}
    />
  );
}


type FixedMaskTextFieldProps<Variant extends TextFieldVariants> = Omit<MaskedTextFieldProps<Variant>, 'mask'>


export function PhoneTextField<Variant extends TextFieldVariants>({ variant, ...props}: FixedMaskTextFieldProps<Variant>) {
  return (
    <MaskedTextField
      variant = {variant as any}
      lazy = {false}
      {...props}
      mask = "+{7}(000)000-00-00"
    />
  );
}


function formatDate(date: Date): string {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return [
    year,
    month < 10 ? '0' + month : month,
    day < 10 ? '0' + day : day
  ].join('-');
}

function parseDate(str: string): Date {
  const yearMonthDay = str.split('-').map(Number);
  return new Date(yearMonthDay[0], yearMonthDay[1] - 1, yearMonthDay[2]);
}

export function DateTextField<Variant extends TextFieldVariants>({ variant, inputProps, ...props }: FixedMaskTextFieldProps<Variant>) {
  return (
    <MaskedTextField
      variant = {variant as any}
      {...props}
      mask = {Date}
      inputProps = {{
        pattern: 'Y-`m-`d',
        ...inputProps,
        format: formatDate,
        parse: parseDate,
        autofix: true,
      }}
    />
  );
}


export function NumberTextField<Variant extends TextFieldVariants>({ variant, inputProps, ...props }: FixedMaskTextFieldProps<Variant>) {
  return (
    <MaskedTextField
      variant = {variant as any}
      {...props}
      mask = {Number}
      inputProps = {{
        ...inputProps,
        autofix: true,
      }}
    />
  );
}
