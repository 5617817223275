import React, { useEffect, useMemo, useState } from 'react';
import { DaDataFio } from 'react-dadata';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import ResultCard from './ResultCard';

import { shuffle } from '../utils/array';
import { formatCurrency } from './utils/intl';
import { ApplicationBankStatusName, ApplicationBankStatus } from './api';
import AVAILABLE_BANKS from './banks';
import { APPROVED_RESULTS, UNAPPROVED_RESULTS } from './parameters';
import { getGridWidth } from './theme';


const TIMER_UPDATE_INTERVAL = 100;
const TIMER_UPDATE_DECREMENT = TIMER_UPDATE_INTERVAL / 1000;

export type ResultsProps = {
  fio: DaDataFio,
  requestedAmount: number,
  processed: boolean,
  banks: ApplicationBankStatus[],
}

export default function Results({ fio, requestedAmount, processed, banks }: ResultsProps) {
  const acceptedBanks = useMemo(() => banks.filter(bank => bank.status === ApplicationBankStatusName.Success), [banks]);
  const totalApproved = acceptedBanks.reduce((sum, bank) => (sum + bank.sum), 0);
  const additionalBanksCount = processed ? (acceptedBanks.length ? APPROVED_RESULTS - acceptedBanks.length : UNAPPROVED_RESULTS) : 0;
  const total = (totalApproved + requestedAmount * additionalBanksCount) || requestedAmount;

  const displayedBanks = useMemo(() => {
    const additionalBanks = AVAILABLE_BANKS.filter(({ id, onlyReal }) => (!onlyReal) && (acceptedBanks.filter(({ bank_id }) => bank_id === id).length === 0));

    shuffle(additionalBanks);

    return [
      ...acceptedBanks,
      ...additionalBanks.slice(0, additionalBanksCount).map(({ id, link }) => ({
        bank_id: id,
        bank_name: '',
        sum: requestedAmount,
        status: ApplicationBankStatusName.Success,
        link,
      }))
    ];
  }, [processed, acceptedBanks, requestedAmount]);

  const [timeLeft, setTimeLeft] = useState(1800);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(timeLeft => (timeLeft > TIMER_UPDATE_DECREMENT) ? timeLeft - TIMER_UPDATE_DECREMENT : 0);
    }, TIMER_UPDATE_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [setTimeLeft]);

  const timeParts = [
    Math.floor(timeLeft / 3600),
    Math.floor(timeLeft / 60) % 60,
    Math.floor(timeLeft) % 60,
  ];

  return (
    <Box
      sx = {(theme) => ({
        px: 2,
        [theme.containerQueries.up('sm')]: {
          textAlign: 'center',
          px: 0,
        },
      })}
    >
      <Typography
        variant = "h2"
        sx={(theme) => ({
          mb: 2,
          ...theme.typography.h3,
          [theme.containerQueries.up('sm')]: {
            mb: 0,
            ...theme.typography.h2,
          },
        })}
      >
        Уважаем{fio?.gender === 'FEMALE' ? 'ая' : 'ый'} {`${fio?.name ?? ''} ${fio?.patronymic ?? ''}`.trim()}!<br />
      </Typography>
      <Typography
        variant = "subtitle1"
        sx={(theme) => ({
          mb: 2,
          ...theme.typography.importantValue,
          [theme.containerQueries.up('sm')]: {
            ...theme.typography.h2,
          },
          '& strong': {
            fontWeight: 'inherit',
            color: theme.palette.success.main,
            [theme.containerQueries.up('sm')]: {
              color: theme.palette.primary.main,
            },
          },
        })}
      >
        Сумма одобренных Вам займов составила <strong>{formatCurrency(total)}</strong> в следующих МФО.
      </Typography>
      <Typography variant="subtitle2">
        Для получения денежных средств перейдите в личный кабинет каждого из МФО.<br />
        {/*Логин и пароль от каждого из кабинетов вам выслан по СМС.*/}
        Обращаем внимание что действие одобрения ограничено.
      </Typography>

      <Typography variant="specialValue" sx={{ my: 3, textAlign: 'center', fontSize: 36 }}>
        {timeParts.map((part, index, array) => {
          const isActive = part || array[index - 1];
          return (
            <React.Fragment key={index}>
              <Typography variant="specialValue" component="span" sx={{ color: isActive ? 'primary.main' : 'text.secondary' }}>{(part < 10 ? '0' : '') + String(part)}</Typography>
              {(index < array.length - 1) && (
                <Typography variant="specialValue" component="span" sx={{ color: isActive ? 'primary.main' : 'text.primary' }}>:</Typography>
              )}
            </React.Fragment>
          );
        })}
      </Typography>

      <Grid
        container
        columnSpacing={2}
        rowSpacing={2}
        sx = {(theme) => ({
          mt: 3,
          p: 2,
          background: 'linear-gradient(to bottom, #DAFFC3 0%, #91DC60 100%)',
          borderRadius: 5,
          boxShadow: '0px 10px 4px rgba(12, 49, 2, 0.01), 0px 6px 3px rgba(12, 49, 2, 0.03), 0px 3px 3px rgba(12, 49, 2, 0.04), 0px 1px 1px rgba(12, 49, 2, 0.05)',
          [theme.containerQueries.up('sm')]: {
            p: 4,
          },
        })}
      >
        {displayedBanks.map(({ bank_id, bank_name, sum, link }, index) => (
          <Grid
            key = {bank_id}
            size = {12}
            sx = {(theme) => ({
              width: '100%',
              [theme.containerQueries.up('sm')]: {
                width: getGridWidth(6),
              },
              [theme.containerQueries.up('md')]: {
                width: getGridWidth(4),
              },
              [theme.containerQueries.up('lg')]: {
                width: getGridWidth(3),
              },
            })}
          >
            <ResultCard
              bankId = {bank_id}
              bankName = {bank_name}
              sum = {sum}
              link = {link}
              index = {index}
            />
          </Grid>
        ))}
        {processed ? null : (
          <Grid
            size = {12}
            sx = {(theme) => ({
              p: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              [theme.containerQueries.up('sm')]: {
                justifyContent: 'flex-start',
                width: getGridWidth(6),
              },
              [theme.containerQueries.up('md')]: {
                width: getGridWidth(4),
              },
              [theme.containerQueries.up('lg')]: {
                width: getGridWidth(3),
              },
            })}
          >
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

