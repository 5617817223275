import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import {
  DaDataSuggestion,

  DaDataAddress,
  AddressSuggestions,

  DaDataBank,
  BankSuggestions,

  DaDataFio,
  FioSuggestions,

  DaDataPartyRussia as DaDataParty,
  PartySuggestions,
} from 'react-dadata';
import { CommonProps as DaDataCommonProps } from 'react-dadata/dist/core-types';

import FilledInput, { FilledInputProps } from '@mui/material/FilledInput';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { DaDataFmsUnit, FmsUnitSuggestions } from './react-dadata-extension/FmsUnitSuggestions';

import { DADATA_API_KEY } from './parameters';


const FilledInputWrapper = forwardRef(function FilledInputWrapper(props: FilledInputProps, ref: React.RefObject<HTMLInputElement>) {
  return (
    <FilledInput {...props} inputRef={ref} />
  );
});


type DaDataComponentType<SuggestionType> = React.ComponentClass<DaDataCommonProps<SuggestionType>>;

type DaDataSuggestionsWrapperProps<SuggestionType> = DaDataCommonProps<SuggestionType> & {
  dadataComponent: DaDataComponentType<SuggestionType>,
}

const DaDataSuggestionsWrapper = forwardRef(function DaDataSuggestionsWrapper<SuggestionType>(
  { dadataComponent: DaDataComponent, ...props }: DaDataSuggestionsWrapperProps<SuggestionType>,
  ref: React.RefObject<{ focus: () => void }>
) {
  const dadataRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus() {
      dadataRef.current?.textInput?.focus();
    }
  }));

  return (
    <DaDataComponent {...props} customInput={FilledInputWrapper} ref={dadataRef} />
  );
});


type DaDataSuggestionsFieldProps<SuggestionType> = Omit<TextFieldProps, 'value' | 'onChange'> & {
  value: DaDataSuggestion<SuggestionType>,
  onChange: (suggestion?: DaDataSuggestion<SuggestionType>) => void,
}

type DaDataSuggestionsFieldInternalProps<SuggestionType> = DaDataSuggestionsFieldProps<SuggestionType> & {
  dadataComponent: DaDataComponentType<SuggestionType>,
}

function DaDataSuggestionsField<SuggestionType>({ dadataComponent, value, onChange, ...props }: DaDataSuggestionsFieldInternalProps<SuggestionType>) {
  return (
    <TextField
      {...props}
      value = {value as any}
      onChange = {onChange as any}
      slots = {{
        ...props.slots,
        input: DaDataSuggestionsWrapper,
      }}
      slotProps = {{
        ...props.slotProps,
        input: {
          ...props.slotProps?.input,
          dadataComponent,
          token: DADATA_API_KEY,
          count: 5,
        } as any,
      }}
      variant = "filled"
      sx = {[
        {
          '& .react-dadata__input': {
            border: 0,
            padding: 0,
            height: 'auto',
            borderRadius: 3,
            fontSize: 15,
          },
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}


export function AddressSuggestionsField(props: DaDataSuggestionsFieldProps<DaDataAddress>) {
  return (
    <DaDataSuggestionsField {...props} dadataComponent={AddressSuggestions} />
  );
}

export function BankSuggestionsField(props: DaDataSuggestionsFieldProps<DaDataBank>) {
  return (
    <DaDataSuggestionsField {...props} dadataComponent={BankSuggestions} />
  );
}

export function FioSuggestionsField(props: DaDataSuggestionsFieldProps<DaDataFio>) {
  return (
    <DaDataSuggestionsField {...props} dadataComponent={FioSuggestions} />
  );
}

export function PartySuggestionsField(props: DaDataSuggestionsFieldProps<DaDataParty>) {
  return (
    <DaDataSuggestionsField {...props} dadataComponent={PartySuggestions} />
  );
}

export function FmsUnitSuggestionsField(props: DaDataSuggestionsFieldProps<DaDataFmsUnit>) {
  return (
    <DaDataSuggestionsField {...props} dadataComponent={FmsUnitSuggestions} />
  );
}
