import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import { formatDecimal } from './utils/intl';

type ValueEditorProps = {
  id: string,
  label: string,
  value: number,
  onChange: (newValue: number) => void,
  formatValue?: (value: number) => string,
}

export default function ValueEditor({ id, label, value, onChange, formatValue }: ValueEditorProps) {
  const [active, setActive] = useState(false);
  const valueRef = useRef<HTMLInputElement>(null);
  const [currentValue, setCurrentValue] = useState(String(value));

  useEffect(() => {
    setCurrentValue(String(value));
  }, [value, setCurrentValue]);

  const formattedValue = useMemo(() => {
    return formatValue ? formatValue(value) : formatDecimal(value);
  }, [formatValue, value]);

  const handleInputKeyDown = useCallback((evt: React.KeyboardEvent<HTMLInputElement>) => {
    const key = evt.key;
    const target = evt.target as HTMLInputElement;

    if (evt.altKey || evt.ctrlKey || evt.metaKey) {
      return;
    }

    if (key === 'Enter') {
      evt.preventDefault();
      target.blur();
    }

    if (key === 'Escape') {
      evt.preventDefault();
      setCurrentValue(String(value));
      setTimeout(() => {
        target.blur();
      });
    }

    if (key.length === 1 && !key.match(/^[0-9]$/)) {
      evt.preventDefault();
    }
  }, [value, setCurrentValue]);

  const handleInputChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(evt.target.value.trim().replace(/[^0-9]/g, ''));
  }, [setCurrentValue]);

  const handleInputBlur = useCallback(() => {
    onChange(Number(currentValue));
    setActive(false);
  }, [currentValue, onChange, setActive]);

  const handleEditClick = useCallback(() => {
    setActive(true);
  }, [setActive]);

  useEffect(() => {
    if (active) {
      valueRef.current.focus();
      valueRef.current.select();
    }
  }, [active]);

  return (
    <>
      <Typography variant="body2" component="label" htmlFor={id}>
        {label}
      </Typography>
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography
          variant = "importantValue"
          component = "span"
          sx = {{ position: 'relative' }}
        >
          {active ?
            (
              <InputBase
                id = {id}
                inputRef = {valueRef}
                value = {currentValue}
                onKeyDown = {handleInputKeyDown}
                onChange = {handleInputChange}
                onBlur = {handleInputBlur}
                sx = {theme => ({
                  ...theme.typography.importantValue,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: 1,
                })}
                inputProps = {{
                  sx: {
                    py: 0,
                    height: 'auto',
                  },
                }}
              />
            )
            :
            null
          }
          {active ? currentValue : formattedValue}
        </Typography>

        <IconButton onClick={handleEditClick} size="small" sx={{ ml: 1, mt: -0.25 }}>
          <EditIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </>
  );
}

function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6287 0.358332L14.0221 0.751665C14.5641 1.29433 14.4821 2.25633 13.8374 2.90033L7.15711 10.8917L4.5291 11.853C4.19911 11.9743 3.87777 11.817 3.81244 11.503C3.79036 11.3891 3.80075 11.2713 3.84244 11.163L4.82244 8.51232L11.4801 0.542998C12.1247 -0.101002 13.0867 -0.184335 13.6287 0.358332Z" fill="currentColor" fillOpacity="0.4"/>
        <path d="M1 15.0683C1 14.4416 1.35021 13.735 2.5 13.735H3.81244H11.8124C12.1661 13.735 12.5052 13.5945 12.7552 13.3444C13.0053 13.0944 13.1458 12.7553 13.1458 12.4016V9.94165C13.1458 9.64833 13.5 8.94165 14.4791 8.94165V9.73497V12.4016C14.4791 13.1089 14.1982 13.7872 13.6981 14.2873C13.198 14.7873 12.5197 15.0683 11.8124 15.0683H3.81244H1Z" fill="currentColor" fillOpacity="0.4"/>
      </svg>
    </SvgIcon>
  );
}
