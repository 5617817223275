import { LOCALE, CURRENCY } from '../parameters';

export type formatNumberOptions = {
  style: 'decimal' | 'currency',
  minimumFractionDigits?: number,
  maximumFractionDigits?: number,
}

export function formatNumber(value: number, options: formatNumberOptions): string {
  try {
    const formatter = new Intl.NumberFormat(LOCALE, { ...options, currency: CURRENCY });

    return formatter.format(value);
  } catch {
    return value.toFixed(options.minimumFractionDigits || 0) + ((options.style === 'currency') ? ` ${CURRENCY}` : '');
  }
}

export function formatCurrency(value: number): string {
  return formatNumber(value, { style: 'currency', maximumFractionDigits: 0 });
}

export function formatDecimal(value: number): string {
  return formatNumber(value, { style: 'decimal', maximumFractionDigits: 0 });
}

export type PluralVariants = {
  zero?: string,
  one: string,
  two?: string,
  few?: string,
  many: string,
  other?: string,
}

export function pluralize(value: number, variants: PluralVariants): string {
  try {
    const rules = new Intl.PluralRules(LOCALE);

    return variants[rules.select(value)];
  } catch {
    return (value === 1) ? variants.one : variants.many;
  }
}

export function formatDays(value: number): string {
  const formattedValue = formatNumber(value, { style: 'decimal', maximumFractionDigits: 0 });

  return pluralize(value, {
    one: `${formattedValue} день`,
    few: `${formattedValue} дня`,
    many: `${formattedValue} дней`,
    other: `${formattedValue} дня`,
  });
}
