import zaymer from '../assets/banks/zaymer.svg';
import webbankir from '../assets/banks/webbankir.svg';
import migcredit from '../assets/banks/migcredit.svg';
import smsfinance from '../assets/banks/smsfinance.svg';
import zaymigo from '../assets/banks/zaymigo.svg';
import adengi from '../assets/banks/adengi.svg';
import turbozaim from '../assets/banks/turbozaim.svg';
import joymoney from '../assets/banks/joymoney.svg';
import lime from '../assets/banks/lime.svg';

type AvailableBank = {
  id: number,
  link: string,
  name: string,
  icon: string,
  onlyReal?: boolean,
}

const AVAILABLE_BANKS: AvailableBank[] = [
  {
    id: -7,
    // link: 'https://www.zaymer.ru/',
    link: 'https://go.cityclub.finance/v2/click-3W1V0-LNmXzy-WDPyg-ca99299b?tl=1&erid=LdtCKWQuk',
    name: 'Займер',
    icon: zaymer,
  },
  {
    id: -6,
    // link: 'https://webbankir.com/',
    link: 'https://go.cityclub.finance/v2/click-AMgKL-LNmXzy-nYl1O-5081b241?tl=1&erid=2VfnxwAJpvk',
    name: 'Webbankir',
    icon: webbankir,
  },
  {
    id: 1005,
    // link: 'https://migcredit.ru/',
    link: 'https://go.cityclub.finance/v2/click-BRnak-LNmXzy-rJNkx-dc7582fe?tl=1',
    name: 'МигКредит',
    icon: migcredit,
  },
  {
    id: 1009,
    // link: 'https://www.smsfinance.ru/',
    link: 'https://go.cityclub.finance/v2/click-3ZNGo-LNmXzy-Y2J8o-9ac52bd3?tl=1&erid=LdtCKZWUz',
    name: 'Smsfinance',
    icon: smsfinance,
  },
  {
    id: 1123,
    // link: 'https://zaymigo.ru/',
    link: 'https://go.cityclub.finance/v2/click-ApZww-LNmXzy-jP2rp-3a2bc1ba?tl=1&erid=LdtCKcsJJ',
    name: 'Займиго',
    icon: zaymigo,
  },
  {
    id: -1,
    // link: 'https://adengi.ru/',
    link: 'https://go.cityclub.finance/v2/click-3y1XW-LNmXzy-X764J-57f52bce?tl=1&erid=2Vfnxwsdkhe',
    name: 'А-Деньги',
    icon: adengi,
  },
  {
    id: -5,
    // link: 'https://turbozaim.ru/',
    link: 'https://go.cityclub.finance/v2/click-BJyl4-LNmXzy-6GoKw-35a5aa8d?tl=1&erid=LdtCK7JvN',
    name: 'Турбозайм',
    icon: turbozaim,
  },
  {
    id: -2,
    // link: 'https://joy.money/',
    link: 'https://go.cityclub.finance/v2/click-3DEza-LNmXzy-07Y6y-dd98c98c?tl=1&erid=LdtCKN67L',
    name: 'Joymoney',
    icon: joymoney,
  },
  {
    id: 1194,
    link: 'https://www.lime-zaim.ru/',
    name: 'Лайм-Займ',
    icon: lime,
    onlyReal: true,
  },
];

export default AVAILABLE_BANKS;
