import React from 'react';

import Slider, { SliderProps } from '@mui/material/Slider';

import AligningMarkLabel from './AligningMarkLabel';

import { formatDays } from './utils/intl';

import { MIN_PERIOD, MAX_PERIOD } from './parameters';

const marks = [MIN_PERIOD, MAX_PERIOD].map(value => ({
  value,
  label: formatDays(value),
}));

export type PeriodSliderProps = SliderProps;

export default function PeriodSlider(props: PeriodSliderProps) {
  return (
    <Slider
      min = {MIN_PERIOD}
      max = {MAX_PERIOD}
      marks = {marks}
      slots = {{
        markLabel: AligningMarkLabel,
      }}
      {...props}
    />
  );
}
