import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import Widget, { WidgetProps } from './Widget';

import { camelCase } from './utils/string';

// From tutorial https://techblog.skeepers.io/create-a-web-component-from-a-react-component-bbe7c5f85ee6
export default class WidgetWebComponent extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    const props = this.getPropsFromAttributes<WidgetProps>();

    const rootContainer = document.createElement('div');
    this.shadowRoot.appendChild(rootContainer);

    const root = ReactDOM.createRoot(rootContainer);
    root.render(
      <StrictMode>
        <Widget
          {...props}
          shadowRoot            = {this.shadowRoot}
          shadowRootContainer   = {rootContainer}
          addExternalStylesheet = {(url: string) => this.addExternalStylesheet(url)}
        />
      </StrictMode>
    );
  }

  disconnectedCallback() {
    for (let index = 0; index < this.externalStylesheets.length; index++) {
      const link = this.externalStylesheets[index];

      link.parentElement?.removeChild(link);
    }

    this.externalStylesheets = [];
  }

  private getPropsFromAttributes<T>(): T {
    const props: Record<string, string> = {};

    for (let index = 0; index < this.attributes.length; index++) {
      const attribute = this.attributes[index];
      props[camelCase(attribute.name)] = attribute.value;
    }

    return props as T;
  }

  private externalStylesheets: HTMLLinkElement[] = [];

  private addExternalStylesheet(url: string): HTMLLinkElement {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href  = url;
    document.head.appendChild(link);
    this.externalStylesheets.push(link);
    return link;
  }

}
