import React, { useCallback, useState } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { formatCurrency, formatDays } from './utils/intl';
import { useForms } from './form';

type SidebarProps = BoxProps & {
  step: number,
};

const CHANCES = [
  5,
  48,
  69,
  90,
  99,
];

const MAX_STEP = CHANCES.length - 2;

export default function Sidebar({ step, ...props }: SidebarProps) {
  const [[form]] = useForms();
  const currentStep = step < 0 ? 0 : (step > MAX_STEP ? MAX_STEP : step);
  const currentChance = CHANCES[currentStep];
  const nextChance = CHANCES[currentStep + 1];
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapsed = useCallback(() => setIsCollapsed(isCollapsed => !isCollapsed), [setIsCollapsed]);

  const collapsedContent = (
    <>
      <Box sx={{ mb: 1 }}>
        <Typography variant="body2">
          Ваш займ на сумму
        </Typography>
        <Typography variant="importantValue" sx={{ mt: 1 }}>
          {formatCurrency(form.fields.amount.value)}
        </Typography>
      </Box>

      <Box sx={{ mb: 1 }}>
        <Typography variant="body2">
          На срок
        </Typography>
        <Typography variant="importantValue" sx={{ mt: 1 }}>
          {formatDays(form.fields.period.value)}
        </Typography>
      </Box>

      <Divider sx={{ my: 1.5 }} />
    </>
  );

  const collapsedFooter = (
    <>
      <Typography variant="body2" sx={{ mt: 1 }}>
        +{nextChance - currentChance}% за {currentStep + 1}-й шаг
      </Typography>
    </>
  );

  return (
    <Box {...props}>
      <Link
        underline = "none"
        component = "button"
        type = "button"
        variant = "body2"
        onClick = {toggleCollapsed}
        sx = {(theme) => ({
          float: 'right',
          mt: 0.375,
          ml: 2,
          display: 'inline-block',
          [theme.containerQueries.up('sm')]: {
            display: 'none',
          },
        })}
      >
        {isCollapsed ? 'Развернуть' : 'Свернуть'}
      </Link>

      <Typography
        variant = "h4"
        sx = {(theme) => ({
          mb: 2,
          display: 'block',
          [theme.containerQueries.up('sm')]: {
            display: 'none',
          },
        })}
      >
        Сводка по заявке
      </Typography>

      <Collapse
        in = {!isCollapsed}
        sx = {(theme) => ({
          display: 'block',
          [theme.containerQueries.up('sm')]: {
            display: 'none',
          },
        })}
      >
        {collapsedContent}
      </Collapse>
      <Box
        sx = {(theme) => ({
          display: 'none',
          [theme.containerQueries.up('sm')]: {
            display: 'block',
          },
        })}
      >
        {collapsedContent}
      </Box>

      <Box>
        <Typography variant="body2">
          Вероятность одобрения
        </Typography>
        <Typography variant="importantValue" sx={{ mt: 1 }}>
          {currentChance}%
        </Typography>

        <LinearProgress variant="buffer" value={currentChance} valueBuffer={nextChance} color="secondary" sx={{ my: 0.5 }} />

        <Collapse
          in = {!isCollapsed}
          sx = {(theme) => ({
            display: 'block',
            [theme.containerQueries.up('sm')]: {
              display: 'none',
            },
          })}
        >
          {collapsedFooter}
        </Collapse>
        <Box
          sx = {(theme) => ({
            display: 'none',
            [theme.containerQueries.up('sm')]: {
              display: 'block',
            },
          })}
        >
          {collapsedFooter}
        </Box>
      </Box>
    </Box>
  );
}
