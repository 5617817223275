import { createContext, useCallback, useContext, useEffect } from 'react';
import { useForm, email, notEmpty, regexp } from '@xvii/useform';
import { DaDataAddress, DaDataFio, DaDataPartyRussia as DaDataParty, DaDataSuggestion } from 'react-dadata';
import { DaDataFmsUnit } from './react-dadata-extension/FmsUnitSuggestions';

import { fullName } from './validators/FullName';
import { minAge } from './validators/MinAge';
import { prohibitedValue } from './validators/ProhibitedValue';
import { ApplicationData, ApplicationDataAddress, ApplicationDataGender, ApplicationDataSocialStatus, ApplicationDataWorkStatus, ApplicationDataWorkPositionType } from './api';
import { juicyScoreGetSessionId } from './juicy-score';
import { MIN_AMOUNT, MAX_AMOUNT, MIN_PERIOD, MAX_PERIOD, MIN_AGE } from './parameters';

export const EMPTY_PHONE_VALUE = '+7(___)___-__-__';
export const EMPTY_FIELD_MESSAGE = 'Это поле необходимо заполнить';
export const EMPTY_NAME_MESSAGE = 'Укажите фамилию, имя и отчество через пробел';
export const EMPTY_AGREEMENT_MESSAGE = 'Для продолжения необходимо согласиться с условиями';
export const WRONG_PHONE_FORMAT_MESSAGE = 'Телефон указан в неверном формате';
export const UNDERAGE_MESSAGE = 'Вы не достигли 18 лет';
export const EMAIL_REGEXP = /^([a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*|)$/;

function useHandleSubmit(onSubmit: () => void) {
  return useCallback((evt: React.FormEvent) => {
    evt.preventDefault();

    onSubmit();
  }, [onSubmit]);
}

function useSetValue<T>(setValue: (value: T) => void) {
  return useCallback((value: T) => {
    setValue(value);
  }, [setValue]);
}

function useFormErrorResetOnChange(form: ReturnType<typeof useForm>) {
  useEffect(() => {
    Object.values(form.fields).forEach(field => {
      const oldSetValue = field.setValue;

      field.setValue = function (...args) {
        oldSetValue.call(field, ...args);
        field.setError(true, undefined);
      };
    })
  }, [form]);
}

export function useFormStep1(onSubmit: (formData: unknown) => void) {
  const form = useForm({
    onSubmit(formData) {
      onSubmit(formData);
    },
    fields: {
      amount: {
        defaultValue: 15000,
        valueType: Number(),
        validators: [
          notEmpty({ errorText: 'Желаемая сумма займа не указана' }),
        ],
      },
      period: {
        defaultValue: 30,
        valueType: Number(),
        validators: [
          notEmpty({ errorText: 'Срок займа не указан' }),
        ],
      },
      fullName: {
        defaultValue: null,
        valueType: null,
        validators: [
          notEmpty({ errorText: EMPTY_NAME_MESSAGE }),
          fullName({ errorText: EMPTY_NAME_MESSAGE }) as any,
        ],
      },
      mobilePhone: {
        defaultValue: '',
        valueType: String(),
        validators: [
          notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
          prohibitedValue({ value: EMPTY_PHONE_VALUE, errorText: EMPTY_FIELD_MESSAGE }) as any,
          regexp({ regexp: /^[^_]+$/, errorText: WRONG_PHONE_FORMAT_MESSAGE }),
        ],
      },
      agreementRules: {
        defaultValue: false,
        valueType: Boolean(),
        validators: [notEmpty({ errorText: EMPTY_AGREEMENT_MESSAGE })],
      },
      agreementMailing: {
        defaultValue: false,
        valueType: Boolean(),
        validators: [notEmpty({ errorText: EMPTY_AGREEMENT_MESSAGE })],
      },
    },
  });

  useFormErrorResetOnChange(form);

  const setAmount = useCallback((value: number) => {
    if (value < MIN_AMOUNT) { value = MIN_AMOUNT; }
    if (value > MAX_AMOUNT) { value = MAX_AMOUNT; }

    form.fields.amount.setValue(value);
  }, [form.fields.amount.setValue]);

  const setPeriod = useCallback((value: number) => {
    if (value < MIN_PERIOD) { value = MIN_PERIOD; }
    if (value > MAX_PERIOD) { value = MAX_PERIOD; }

    form.fields.period.setValue(value);
  }, [form.fields.period.setValue]);

  const setMobilePhone = useSetValue(form.fields.mobilePhone.setValue);

  const handleSubmit = useHandleSubmit(form.handleSubmit);

  return [form, setAmount, setPeriod, setMobilePhone, handleSubmit] as const;
}

export function useFormStep2(onSubmit: (formData: unknown) => void, onReverse: () => void) {
  const form = useForm({
    onSubmit(formData) {
      onSubmit(formData);
    },
    fields: {
      passportNumber: {
        defaultValue: '',
        valueType: String(),
        validators: [
          notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
          regexp({ regexp: /^[^_]+$/, errorText: EMPTY_FIELD_MESSAGE }),
        ],
      },
      passportDate: {
        defaultValue: '',
        valueType: String(),
        validators: [
          notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
          regexp({ regexp: /^[^_]+$/, errorText: EMPTY_FIELD_MESSAGE }),
        ],
      },
      passportUnit: {
        defaultValue: null,
        valueType: null,
        validators: [
          notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
        ],
      },
      birthDate: {
        defaultValue: '',
        valueType: String(),
        validators: [
          notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
          regexp({ regexp: /^[^_]+$/, errorText: EMPTY_FIELD_MESSAGE }),
          minAge({ minAge: MIN_AGE, errorText: UNDERAGE_MESSAGE }) as any,
        ],
      },
      birthPlace: {
        defaultValue: '',
        valueType: String(),
        validators: [
          notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
        ],
      },
      registrationAddress: {
        defaultValue: null,
        valueType: null,
        validators: [
          notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
        ],
      },
      residenceAddressEmpty: {
        defaultValue: true,
        valueType: Boolean(),
        validators: [],
      },
      residenceAddress: {
        defaultValue: null,
        valueType: null,
        validators: [],
      },
    },
  });

  useFormErrorResetOnChange(form);

  const setPassportNumber = useSetValue(form.fields.passportNumber.setValue);

  const setPassportDate = useSetValue(form.fields.passportDate.setValue);

  const setBirthDate = useSetValue(form.fields.birthDate.setValue);

  const handleResidenceAddressEmptyChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    const residenceAddressEmpty = evt.target.checked;

    form.fields.residenceAddressEmpty.setValue(residenceAddressEmpty);

    form.fields.residenceAddress.replaceValidators(residenceAddressEmpty ?
      [] :
      [
        notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
      ]
    );
  }, [form.fields.residenceAddressEmpty.setValue, form.fields.residenceAddress.replaceValidators]);

  const handleSubmit = useHandleSubmit(form.handleSubmit);

  const handleReverse = onReverse;

  return [form, setPassportNumber, setPassportDate, setBirthDate, handleResidenceAddressEmptyChange, handleSubmit, handleReverse] as const;
}

export function useFormStep3(onSubmit: (formData: unknown) => void, onReverse: () => void) {
  const form = useForm({
    onSubmit(formData) {
      onSubmit(formData);
    },
    fields: {
      jobType: {
        defaultValue: '',
        valueType: String(),
        validators: [
          notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
        ],
      },
      organizationName: {
        defaultValue: null,
        valueType: null,
        validators: [],
      },
      workPhone: {
        defaultValue: '',
        valueType: String(),
        validators: [],
      },
      workPositionName: {
        defaultValue: '',
        valueType: String(),
        validators: [],
      },
      workPositionType: {
        defaultValue: '',
        valueType: String(),
        validators: [],
      },
    },
  });

  useFormErrorResetOnChange(form);

  const handleJobTypeChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    const jobType = evt.target.value;

    form.fields.jobType.setValue(jobType);

    const isEmployed = jobType && jobType !== 'unemployed';

    form.fields.organizationName.replaceValidators(isEmployed ?
      [
        notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
      ] :
      []
    );

    form.fields.workPhone.replaceValidators(isEmployed ?
      [
        notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
        prohibitedValue({ value: EMPTY_PHONE_VALUE, errorText: EMPTY_FIELD_MESSAGE }) as any,
        regexp({ regexp: /^[^_]+$/, errorText: WRONG_PHONE_FORMAT_MESSAGE }),
      ] :
      []
    );

    form.fields.workPositionName.replaceValidators(isEmployed ?
      [
        notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
      ] :
      []
    );

    form.fields.workPositionType.replaceValidators(isEmployed ?
      [
        notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
      ] :
      []
    );
  }, [
    form.fields.jobType.setValue,
    form.fields.organizationName.replaceValidators,
    form.fields.workPhone.replaceValidators,
    form.fields.workPositionName.replaceValidators,
    form.fields.workPositionType.replaceValidators,
  ]);

  const setWorkPhone = useSetValue(form.fields.workPhone.setValue);

  const handleSubmit = useHandleSubmit(form.handleSubmit);

  const handleReverse = onReverse;

  return [form, handleJobTypeChange, setWorkPhone, handleSubmit, handleReverse] as const;
}

export function useFormStep4(onSubmit: (formData: unknown) => void, onReverse: () => void) {
  const form = useForm({
    onSubmit(formData) {
      onSubmit(formData);
    },
    fields: {
      personalIncome: {
        defaultValue: '',
        valueType: String(),
        validators: [
          notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
        ],
      },
      email: {
        defaultValue: '',
        valueType: String(),
        validators: [
          notEmpty({ errorText: EMPTY_FIELD_MESSAGE }),
          email({ errorText: 'Вы должны ввести корректный адрес электронной почты'/*, regexp: EMAIL_REGEXP*/ }),
        ],
      },
    },
  });

  useFormErrorResetOnChange(form);

  const handleSubmit = useHandleSubmit(form.handleSubmit);

  const handleReverse = onReverse;

  return [form, handleSubmit, handleReverse] as const;
}

export const FormContext = createContext<[
  ReturnType<typeof useFormStep1>,
  ReturnType<typeof useFormStep2>,
  ReturnType<typeof useFormStep3>,
  ReturnType<typeof useFormStep4>,
]>(null);
export const FormContextProvider = FormContext.Provider;

export function useForms() {
  return useContext(FormContext);
}


export type ApplicationFields = {
  amount: number,
  period: number,
  fullName: DaDataSuggestion<DaDataFio>,
  mobilePhone: string,
  passportNumber: string,
  passportDate: string,
  passportUnit: DaDataSuggestion<DaDataFmsUnit>,
  birthDate: string,
  birthPlace: string,
  registrationAddress: DaDataSuggestion<DaDataAddress>,
  residenceAddressEmpty: boolean,
  residenceAddress: DaDataSuggestion<DaDataAddress>,
  jobType: string,
  organizationName: DaDataSuggestion<DaDataParty>,
  workPhone: string,
  workPositionName: string,
  workPositionType: string,
  personalIncome: string,
  email: string,
}

function addressToApi(
  address: Pick<
    DaDataAddress,
    'country_iso_code' | 'postal_code' | 'region_kladr_id' | 'area_with_type' | 'city_with_type' | 'settlement_with_type' | 'street_with_type' | 'house' |
    'block_type_full' | 'block' | 'flat' | 'okato' | 'oktmo' | 'kladr_id' | 'fias_id'
  >
): ApplicationDataAddress {
  if (!address) { return null; }

  return {
    country_code: address.country_iso_code,
    index: address.postal_code,
    region: address.region_kladr_id?.slice(0,2),
    area: address.area_with_type,
    city: address.city_with_type,
    street: [address.settlement_with_type, address.street_with_type].filter(s => !!s).join(', '),
    house: address.house,
    housing: address.block_type_full === 'корпус' ? address.block : null,
    building: address.block_type_full === 'строение' ? address.block : null,
    flat: address.flat,
    // start_date: null,
    okato: address.okato,
    oktmo: address.oktmo,
    kladr: address.kladr_id,
    fias: address.fias_id,
  };
}

export async function getApplicationData(fields: ApplicationFields): Promise<ApplicationData> {
  const {
    amount,
    period,
    fullName,
    mobilePhone,
    passportNumber,
    passportDate,
    passportUnit,
    birthDate,
    birthPlace,
    registrationAddress,
    residenceAddressEmpty,
    residenceAddress,
    jobType,
    organizationName,
    workPhone,
    workPositionName,
    workPositionType,
    personalIncome,
    email,
  } = fields;

  const [passportSeries, passportNo] = passportNumber?.split(' ') ?? [];
  const isEmployed = jobType !== 'unemployed';

  return {
    is_agree_personal: true,
    mobile: mobilePhone,
    surname: fullName?.data?.surname,
    name: fullName?.data?.name,
    patronymic: fullName?.data?.patronymic,
    birth_date: birthDate,
    birth_place: birthPlace,
    gender: ({
      MALE: ApplicationDataGender.Male,
      FEMALE: ApplicationDataGender.Female,
      UNKNOWN: null,
    })[fullName?.data?.gender],
    income_personal: Number(personalIncome.replace(',', '.')),
    amount: amount,
    term_day: period,
    email: email,
    work_name: isEmployed ? organizationName?.value : null,
    work_position: isEmployed ? workPositionName : null,
    work_phone: isEmployed ? workPhone : null,
    country: registrationAddress.data?.country,
    social_status: ({
      employed: ApplicationDataSocialStatus.Working,
      selfEmployed: ApplicationDataSocialStatus.Businessman,
      unemployed: ApplicationDataSocialStatus.Unemployed,
    })[jobType],
    work_status: ({
      employed: ApplicationDataWorkStatus.Working,
      selfEmployed: ApplicationDataWorkStatus.Businessman,
      unemployed: ApplicationDataWorkStatus.Unemployed,
    })[jobType],
    work_position_type: isEmployed ? ({
      ceo: ApplicationDataWorkPositionType.TopManager,
      manager: ApplicationDataWorkPositionType.Administrator,
      specialist: ApplicationDataWorkPositionType.Specialist,
      worker: ApplicationDataWorkPositionType.Specialist,
      servicePersonnel: ApplicationDataWorkPositionType.MilitaryContract,
    })[workPositionType] : null,
    company_name: isEmployed ? organizationName?.value : null,
    address_reg: addressToApi(registrationAddress?.data),
    address_live: addressToApi(residenceAddressEmpty ? registrationAddress?.data : residenceAddress?.data),
    address_work: isEmployed ? addressToApi(organizationName?.data?.address?.data) : null,
    passport: {
      issue_by: passportUnit?.data?.name,
      issue_code: passportUnit?.data?.code,
      issue_date: passportDate,
      number: passportNo,
      series: passportSeries,
    },
    user_agent: navigator.userAgent,
    juicy_session_id: await juicyScoreGetSessionId(),
  };
}
