import React from 'react';

import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import AmountSlider from './AmountSlider';
import { FioSuggestionsField } from './DaDataTextField';
import { PhoneTextField } from './MaskedTextField';
import PeriodSlider from './PeriodSlider';
import StepForm, { StepFormProps } from './StepForm';
import ValueEditor from './ValueEditor';

import { formatCurrency, formatDays } from './utils/intl';
import { useGetUrl } from './base-url';
import { useForms } from './form';
import { getGridWidth } from './theme';

import agreementPersonal from '../assets/agreement-personal.pdf';
import agreementMailing from '../assets/agreement-mailing.pdf';

export type Step1FormProps = Omit<StepFormProps, 'header' | 'children' | 'footer' | 'onSubmit'>;

export default function Step1Form({ stepper, ...props }: Step1FormProps) {
  const [[form, setAmount, setPeriod, setMobilePhone, handleSubmit]] = useForms();
  const getUrl = useGetUrl();

  return (
    <StepForm
      header = "Выберите сумму и срок займа"
      stepper = {stepper}
      footer = {
        <Typography variant="body2">
          Заполняя форму, я принимаю <Link href={getUrl(agreementPersonal)} target="_blank" rel="noopener">политику обработки персональных данных</Link> и
          даю <Link href={getUrl(agreementMailing)} target="_blank" rel="noopener">согласие на получение рекламной рассылки</Link>
        </Typography>
      }
      onSubmit = {handleSubmit}
      {...props}
    >
      <Grid
        container
        columnSpacing = {0}
        rowSpacing = {4.5}
        sx = {{
          mt: 0.5,
          mb: 2.5,
          px: 2,
          py: 3,
          border: '1px solid #E4E4E7',
          borderRadius: 3
        }}
      >
        <Grid
          size = {12}
          sx = {{
            width: '100%',
          }}
        >
          <ValueEditor
            id = "amount-editor"
            label = "Желаемая сумма кредита"
            value = {form.fields.amount.value}
            onChange = {setAmount}
            formatValue = {formatCurrency}
          />
          <AmountSlider
            id = "amount"
            name = "amount"
            value = {form.fields.amount.value}
            onChange = {form.fields.amount.handleChange as unknown as (evt: Event) => void}
          />
        </Grid>

        <Grid
          size = {12}
          sx = {(theme) => ({
            width: '100%',
            [theme.containerQueries.up('sm')]: {
              width: getGridWidth(8),
            },
            [theme.containerQueries.up('md')]: {
              width: getGridWidth(6),
            },
            [theme.containerQueries.up('lg')]: {
              width: getGridWidth(4),
            },
          })}
        >
          <ValueEditor
            id = "period-editor"
            label = "Срок"
            value = {form.fields.period.value}
            onChange = {setPeriod}
            formatValue = {formatDays}
          />
          <PeriodSlider
            id = "period"
            name = "period"
            value = {form.fields.period.value}
            onChange = {form.fields.period.handleChange as unknown as (evt: Event) => void}
          />
        </Grid>
      </Grid>

      <Typography variant="h4">
        Контактная информация
      </Typography>
      <FioSuggestionsField
        label = "Фамилия, имя и отчество*"
        margin = "normal"
        fullWidth
        id = "full-name"
        name = "full-name"
        value = {form.fields.fullName.value}
        onChange = {form.fields.fullName.setValue}
        error = {form.fields.fullName.error}
        helperText = {form.fields.fullName.errorText}
      />
      <PhoneTextField
        label = "Мобильный телефон*"
        variant = "filled"
        margin = "normal"
        fullWidth
        id = "mobile-phone"
        name = "mobile-phone"
        value = {form.fields.mobilePhone.value}
        onAccept = {setMobilePhone}
        error = {form.fields.mobilePhone.error}
        helperText = {form.fields.mobilePhone.errorText}
        InputLabelProps = {{ shrink: true }}
      />
    </StepForm>
  );
}
