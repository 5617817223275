import { alpha } from '@mui/system/colorManipulator';
import { createTheme, Theme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';


declare module '@mui/material/styles' {
  interface TypographyVariants {
    importantValue: React.CSSProperties;
    specialValue: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    importantValue?: React.CSSProperties;
    specialValue?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    importantValue: true;
    specialValue: true;
  }
}


export const FONT_FAMILY_MAIN = '"Noto Sans", sans-serif';
export const FONT_FAMILY_HEADER = '"Azo Sans", Overpass, sans-serif';
export const FONT_FAMILY_SPECIAL = '"Do Hyeon", sans-serif';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#D13730',
    },
    secondary: {
      main: '#347570',
      light: alpha('#91DC60', 0.24),
    },
    success: {
      main: '#50882B',
    },
    error: {
      main: '#F52222',
      light: alpha('#FF7070', 0.15),
    },
    text: {
      primary: alpha('#141718', 0.85),
      secondary: alpha('#141718', 0.54),
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
  },

  typography: {
    fontSize: 16,
    fontFamily: FONT_FAMILY_MAIN,

    h2: {
      fontFamily: FONT_FAMILY_HEADER,
      fontSize: 32,
      lineHeight: 1.328,
      fontWeight: 500,
    },

    h3: {
      fontFamily: FONT_FAMILY_HEADER,
      fontSize: 24,
      lineHeight: 1.167,
      fontWeight: 500,
    },

    h4: {
      fontFamily: FONT_FAMILY_HEADER,
      fontSize: 18,
      lineHeight: 1.333,
      fontWeight: 500,
    },

    subtitle1: {
      fontFamily: FONT_FAMILY_HEADER,
      fontSize: 20,
      lineHeight: 1.328,
      fontWeight: 400,
    },

    subtitle2: {
      fontFamily: FONT_FAMILY_HEADER,
      fontSize: 14,
      lineHeight: 1.429,
      fontWeight: 400,
      color: alpha('#141718', 0.54),
    },

    body1: {
      fontSize: 15,
    },

    body2: {
      fontSize: 13,
      color: alpha('#141718', 0.54),
    },

    button: {
      fontFamily: FONT_FAMILY_HEADER,
      fontSize: 15,
      lineHeight: 1.333,
      fontWeight: 500,
      textTransform: 'none',
    },

    importantValue: {
      fontFamily: FONT_FAMILY_HEADER,
      fontSize: 20,
      lineHeight: 1.2,
      fontWeight: 500,
    },

    specialValue: {
      fontFamily: FONT_FAMILY_SPECIAL,
      lineHeight: 1.25,
      letterSpacing: '0.25em',
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => theme.unstable_sx({
          px: 8,
          py: 2.25,
          borderRadius: 3,
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: FONT_FAMILY_HEADER,
          fontSize: 16,
          lineHeight: 1.5,
          fontWeight: 500,
        },
        label: ({ theme }) => theme.unstable_sx({
          px: 2.75,
          py: 0.5,
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderWidth: '0px 0px 2px',
          borderColor: '#f4f4f5',
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          variants: [
            {
              style: ({ theme }) => theme.unstable_sx({
                borderRadius: 3,
              }),
            },
            {
              props: { error: true },
              style: ({ theme }) => theme.unstable_sx({
                backgroundColor: theme.palette.error.light,
              }),
            },
          ],
        },
        input: ({ theme }) => theme.unstable_sx({
          px: 2,
          width: 'calc(100% - 32px)',
          textOverflow: 'ellipsis',
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'filled' },
              style: {
                left: '4px',
                maxWidth: 'calc(100% - 32px)',
              },
            },
          ],
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '8px',
          borderRadius: '4px',
        },
        bar: {
          borderRadius: '4px',
        },
        bar2Buffer: {
          variants: [
            {
              props: { color: 'secondary' },
              style: ({ theme }) => ({
                backgroundColor: theme.palette.secondary.light,
              }),
            },
          ],
        },
        dashed: {
          animation: 'none',
          backgroundImage: 'none',
          backgroundColor: '#F6F7F8',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          color: '#126DF7',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: '2px',
        },
        thumb: {
          width: '12px',
          height: '12px',
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { active: false, completed: false },
              style: {
                padding: 0,
              },
            },
          ],
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fontSize: 24,
          variants: [
            {
              props: { active: false, completed: false },
              style: {
                color: '#fff',
              },
            },
            {
              props: { active: true },
              style: ({ theme }) => ({
                boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.3)}`,
                borderRadius: '50%',
              }),
            },
          ],
        },
        text: {
          fontSize: 15,
          lineHeight: 1.6,
          variants: [
            {
              props: { active: false, completed: false },
              style: {
                fill: '#A1A1AA',
              },
            },
            {
              props: { active: true },
              style: {
                fontWeight: 700,
              },
            },
          ],
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          paddingRight: 0,
        },
        labelContainer: ({ theme }) => theme.unstable_sx({
          '&:not(:empty)': {
            ml: 1,
          },
        }),
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: '#E4E4E7',
          variants: [
            {
              props: { active: true },
              style: ({ theme }) => ({
                borderColor: theme.palette.primary.main,
              }),
            },
            {
              props: { completed: true },
              style: ({ theme }) => ({
                borderColor: theme.palette.primary.main,
              }),
            },
            {
              props: { orientation: 'horizontal', active: false, completed: false },
              style: ({ theme }) => theme.unstable_sx({
                '.MuiStepConnector-root.Mui-active + .MuiStep-root + .MuiStepConnector-root &': {
                  borderImage: `linear-gradient(to right, ${theme.palette.primary.main} 0%, #E4E4E7 100%)`,
                  borderImageSlice: 1,
                },
              }),
            },
          ],
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          importantValue: 'div',
          specialValue: 'div',
        },
      },
    },
  },
});

theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.containerQueries.up('sm')]: {
    ...theme.typography.h3,
  },
};

theme.typography.subtitle2 = {
  ...theme.typography.subtitle2,
  [theme.containerQueries.up('sm')]: {
    ...theme.typography.subtitle1,
  },
};

export default function withParentTheme(parentTheme: Theme) {
  return createTheme(deepmerge(parentTheme, theme));
}

export function getGridWidth(count: number): string {
  return `calc(100% * ${count} / var(--Grid-columns) - (var(--Grid-columns) - ${count}) * (var(--Grid-columnSpacing) / var(--Grid-columns)))`;
}
