import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';

import { formatCurrency } from './utils/intl';
import AVAILABLE_BANKS from './banks';
import { useGetUrl } from './base-url';

type ResultCardProps = {
  bankId: number,
  bankName: string,
  sum: number,
  link: string,
  index: number,
}

export default function ResultCard({ bankId, bankName, sum, link, index }: ResultCardProps) {
  const getUrl = useGetUrl();

  const bank = useMemo(() => AVAILABLE_BANKS.filter(bank => bank.id === bankId)[0], [bankId]);
  const bankIcon = bank?.icon;

  return (
    <Grid
      container
      rowSpacing={5}
      sx = {{
        p: 3,
        pb: 4,
        borderRadius: 5,
        backgroundColor: 'background.default',
        boxShadow: '0px 10px 4px rgba(10, 49, 2, 0.01), 0px 6px 3px rgba(10, 49, 2, 0.03), 0px 2px 2px rgba(10, 49, 2, 0.05), 0px 1px 1px rgba(10, 49, 2, 0.06)',
        textAlign: 'center',
      }}
    >
      <Grid size={12}>
        {bankIcon ?
          (
            <Box>
              <img src={getUrl(bankIcon)} alt={bankName} height="68" />
            </Box>
          )
          :
          null
        }
        <Box
          sx = {{
            display: 'inline-block',
            fontFamily: 'button.fontFamily',
            fontSize: 16,
            lineHeight: 1.5,
            px: 2.75,
            py: 0.5,
            borderRadius: 5,
            ...((index % 2) ?
              {
                color: '#FFFFFF',
                backgroundColor: '#91DC60',
                fontWeight: 700,
              }
              :
              {
                color: '#125248',
                backgroundColor: '#DEFDF8',
                fontWeight: 500,
              }
            )
          }}
        >
          Одобрено {formatCurrency(sum)}
        </Box>
      </Grid>
      <Grid size={12}>
        <Button
          variant = "contained"
          component = "a"
          href = {link}
          target = "_blank"
          rel = "noopener"
          sx = {{
            px: 2,
            py: 1.25,
            width: 1,
            borderRadius: 3,
            backgroundColor: '#347570',
            fontSize: 16,
            lineHeight: 1.125,
            fontWeight: 700,
          }}
        >
          Получить
        </Button>
      </Grid>
    </Grid>
  );
}
