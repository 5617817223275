import { DefaultValidatorProps, Validator, ValidatorProps  } from '@xvii/useform/dist/Validator';
import { getAge, parseDate } from '../../utils/date';

type MinAgeProps = DefaultValidatorProps & {
  minAge: number,
}

export class MinAgeValidator extends Validator<string> {
  minAge: number;

  constructor(props: ValidatorProps<string, MinAgeProps>) {
    super(props);
  
    this.minAge = props.params.minAge;
  }

  validate(): boolean {
    const isValid = getAge(parseDate(this.field.value), new Date()) >= this.minAge;
    this.setError(isValid);
    return isValid;
  }
}

export function minAge(params: MinAgeProps): [typeof MinAgeValidator, MinAgeProps] {
  return [MinAgeValidator, params]
}
