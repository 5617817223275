import React from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid2';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { AddressSuggestionsField, FmsUnitSuggestionsField } from './DaDataTextField';
import MaskedTextField, { DateTextField } from './MaskedTextField';
import StepForm, { StepFormProps, BackButton } from './StepForm';

import { useForms } from './form';
import { getGridWidth } from './theme';

export type Step2FormProps = Omit<StepFormProps, 'header' | 'children' | 'footer' | 'onSubmit'>;

export default function Step2Form({ stepper, ...props }: Step2FormProps) {
  const [, [form, setPassportNumber, setPassportDate, setBirthDate, handleResidenceAddressEmptyChange, handleSubmit, handleReverse]] = useForms();

  return (
    <StepForm
      header = "Заполните паспортные данные"
      stepper = {stepper}
      footer = {
        <BackButton onClick={handleReverse} />
      }
      onSubmit = {handleSubmit}
      {...props}
    >
      <Grid container columnSpacing={2} rowSpacing={2} sx={{ mt: 0.5, mb: 2.5 }}>
        <Grid
          size = {12}
          sx = {(theme) => ({
            width: '100%',
            [theme.containerQueries.up('sm')]: {
              width: getGridWidth(6),
            },
          })}
        >
          <MaskedTextField
            mask = "0000 000000"
            label = "Серия и номер*"
            variant = "filled"
            fullWidth
            autoFocus
            id = "passport-number"
            name = "passport-number"
            value = {form.fields.passportNumber.value}
            onAccept = {setPassportNumber}
            error = {form.fields.passportNumber.error}
            helperText = {form.fields.passportNumber.errorText}
            InputLabelProps = {{ shrink: true }}
          />
        </Grid>
        <Grid
          size = {12}
          sx = {(theme) => ({
            width: '100%',
            [theme.containerQueries.up('sm')]: {
              width: getGridWidth(6),
            },
          })}
        >
          <DateTextField
            label = "Дата выдачи*"
            variant = "filled"
            fullWidth
            id = "passport-date"
            name = "passport-date"
            value = {form.fields.passportDate.value}
            onAccept = {setPassportDate}
            error = {form.fields.passportDate.error}
            helperText = {form.fields.passportDate.error ? form.fields.passportDate.errorText : 'Введите дату в формате ГГГГ-ММ-ДД'}
            InputLabelProps = {{ shrink: true }}
          />
        </Grid>
        <Grid
          size = {12}
          sx = {(theme) => ({
            width: '100%',
            [theme.containerQueries.up('sm')]: {
              width: getGridWidth(12),
            },
          })}
        >
          <FmsUnitSuggestionsField
            label = "Кем выдан*"
            fullWidth
            id = "passport-unit-name"
            name = "passport-unit-name"
            value = {form.fields.passportUnit.value}
            onChange = {form.fields.passportUnit.setValue}
            error = {form.fields.passportUnit.error}
            helperText = {form.fields.passportUnit.error ? form.fields.passportUnit.errorText : 'Введите название или код подразделения'}
          />
        </Grid>
        <Grid
          size = {12}
          sx = {(theme) => ({
            width: '100%',
            [theme.containerQueries.up('sm')]: {
              width: getGridWidth(6),
            },
          })}
        >
          <DateTextField
            label = "Дата рождения*"
            variant = "filled"
            fullWidth
            id = "birth-date"
            name = "birth-date"
            value = {form.fields.birthDate.value}
            onAccept = {setBirthDate}
            error = {form.fields.birthDate.error}
            helperText = {form.fields.birthDate.error ? form.fields.birthDate.errorText : 'Введите дату в формате ГГГГ-ММ-ДД'}
            InputLabelProps = {{ shrink: true }}
          />
        </Grid>
        <Grid
          size = {12}
          sx = {(theme) => ({
            width: '100%',
            [theme.containerQueries.up('sm')]: {
              width: getGridWidth(6),
            },
          })}
        >
          <TextField
            label = "Место рождения*"
            variant = "filled"
            fullWidth
            id = "birth-place"
            name = "birth-place"
            value = {form.fields.birthPlace.value}
            onChange = {form.fields.birthPlace.handleChange}
            error = {form.fields.birthPlace.error}
            helperText = {form.fields.birthPlace.errorText}
          />
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{ mt: 4 }}>
        Адрес постоянной регистрации
      </Typography>
      <AddressSuggestionsField
        label = "Адрес постоянной регистрации*"
        margin = "normal"
        fullWidth
        id = "registration-address"
        name = "registration-address"
        value = {form.fields.registrationAddress.value}
        onChange = {form.fields.registrationAddress.setValue}
        error = {form.fields.registrationAddress.error}
        helperText = {form.fields.registrationAddress.errorText}
      />
      <Typography variant="h4" sx={{ mt: 4 }}>
        Адрес фактического проживания
      </Typography>
      <FormControlLabel
        label = "Совпадает с адресом регистрации"
        control={
          <Checkbox
            checked = {form.fields.residenceAddressEmpty.value}
            onChange = {handleResidenceAddressEmptyChange}
          />
        }
      />
      <Collapse in={!form.fields.residenceAddressEmpty.value}>
        <Box>
          <AddressSuggestionsField
            label = "Адрес фактического проживания*"
            margin = "normal"
            fullWidth
            id = "residence-region"
            name = "residence-region"
            value = {form.fields.residenceAddress.value}
            onChange = {form.fields.residenceAddress.setValue}
            error = {form.fields.residenceAddress.error}
            helperText = {form.fields.residenceAddress.errorText}
          />
        </Box>
      </Collapse>
    </StepForm>
  );
}
