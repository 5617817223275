import React from 'react';

import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';

import { NumberTextField } from './MaskedTextField';
import StepForm, { StepFormProps, BackButton } from './StepForm';

import { useForms } from './form';

export type Step4FormProps = Omit<StepFormProps, 'header' | 'children' | 'footer' | 'onSubmit'>;

export default function Step4Form({ stepper, ...props }: Step4FormProps) {
  const [, , , [form, handleSubmit, handleReverse]] = useForms();

  return (
    <StepForm
      header = "Дополнительные сведения"
      stepper = {stepper}
      footer = {
        <BackButton onClick={handleReverse} />
      }
      onSubmit = {handleSubmit}
      {...props}
    >
      <Grid container columnSpacing={2} rowSpacing={2} sx={{ mt: 0.5, mb: 2.5 }}>
        <Grid size={12}>
          <NumberTextField
            label = "Персональный доход*"
            variant = "filled"
            fullWidth
            autoFocus
            id = "personal-income"
            name = "personal-income"
            value = {form.fields.personalIncome.value}
            onChange = {form.fields.personalIncome.handleChange}
            error = {form.fields.personalIncome.error}
            helperText = {form.fields.personalIncome.errorText}
            inputProps = {{
              min: 0,
            }}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            label = "Электронная почта*"
            variant = "filled"
            fullWidth
            id = "email"
            name = "email"
            value = {form.fields.email.value}
            onChange = {form.fields.email.handleChange}
            error = {form.fields.email.error}
            helperText = {form.fields.email.errorText}
          />
        </Grid>
      </Grid>
    </StepForm>
  );
}
