export function formatDate(date: Date): string {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return [
    year,
    String(month).padStart(2, '0'),
    String(day).padStart(2, '0'),
  ].join('-');
}

export function parseDate(str: string): Date {
  const yearMonthDay = str.split('-').map(Number);
  return new Date(yearMonthDay[0], yearMonthDay[1] - 1, yearMonthDay[2]);
}

export function getAge(fromDate: Date, toDate: Date): number {
  let years = toDate.getFullYear() - fromDate.getFullYear();

  if (
    toDate.getMonth() < fromDate.getMonth() ||
    toDate.getMonth() == fromDate.getMonth() && toDate.getDate() < fromDate.getDate()
  ) {
    years--;
  }

  return years;
}
