import React from 'react';

import { BaseSuggestions } from 'react-dadata/dist/esm/base-suggestions';
import { HighlightWords } from 'react-dadata/dist/esm/highlight-words';

// Мы не можем реализовать компонент на TS, потому что в react-dadata .js и .d.ts лежат в несовместимых локациях
// Поэтому здесь мы импортируем код из одного места, а в соседнем .d.ts - определения типов из другого

export class FmsUnitSuggestions extends BaseSuggestions {
  loadSuggestionsUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit';

  getLoadSuggestionsData = () => {
    const { count } = this.props;
    const { query } = this.state;

    return {
      query,
      count: count || 10,
    };
  };

  getSuggestionKey = (suggestion) => `${suggestion.data.code} ${suggestion.value}`;

  renderOption = (suggestion) => {
    const { renderOption, highlightClassName } = this.props;
    const { query } = this.state;

    return renderOption ? (
      renderOption(suggestion, query)
    ) : (
      <div>
        <div>
          <HighlightWords
            highlightClassName={highlightClassName || 'react-dadata--highlighted'}
            words={this.getHighlightWords()}
            text={suggestion.value}
          />
        </div>
        <div className="react-dadata__suggestion-subtitle">
          {suggestion.data.code && (
            <div className="react-dadata__suggestion-subtitle-item">
              <HighlightWords
                highlightClassName={highlightClassName || 'react-dadata--highlighted'}
                words={this.getHighlightWords()}
                text={suggestion.data.code}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
}
