import { createContext, useCallback, useContext } from 'react';

export const BaseUrlContext = createContext<string>('');
export const BaseUrlContextProvider = BaseUrlContext.Provider;

export function useBaseUrl() {
  return useContext(BaseUrlContext);
}

export function useGetUrl() {
  const baseUrl = useBaseUrl();

  return useCallback((url: string) => {
    if (url.match(/^data:/)) {
      return url;
    }

    return baseUrl + url;
  }, [baseUrl]);
}
