export const DADATA_API_KEY = process.env.DADATA_API_KEY ?? '6d12e40aaa94b832a08395a7de4e3884a26f54f3';

export const FIRANO_URL = process.env.FIRANO_URL ?? 'https://firano.ru';

export const JUICY_SCRIPT_URL = 'https://score.juicyscore.com/static/js.js';

export const LOCALE = 'ru-RU';

export const CURRENCY = 'RUB';

export const MIN_AMOUNT = 1000;
export const MAX_AMOUNT = 300000;

export const MIN_PERIOD = 1;
export const MAX_PERIOD = 365;

export const MIN_AGE = 18;

export const APPROVED_RESULTS = 4;
export const UNAPPROVED_RESULTS = 8;
