declare global {
  interface Window {
    juicyScoreApi: any;
  }
}

export function juicyScoreComplete() {
  window.juicyScoreApi?.manuallyComplete.dispatchEvent(new Event('click'));
}

export function juicyScoreNext() {
  window.juicyScoreApi?.manuallyNextButton.dispatchEvent(new Event('click'));
}

export function juicyScoreStopPing() {
  window.juicyScoreApi?.manuallyStopPing.dispatchEvent(new Event('click'));
}

export async function juicyScoreGetSessionId(): Promise<string> {
  return await window.juicyScoreApi?.getSessionId();
}
