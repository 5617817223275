import { DefaultValidatorProps, Validator } from '@xvii/useform/dist/Validator';
import { DaDataFio, DaDataSuggestion } from 'react-dadata';

type FullNameProps = DefaultValidatorProps

export class FullNameValidator extends Validator<DaDataSuggestion<DaDataFio>> {
  validate(): boolean {
    const isValid = !!this.field.value?.data.name && !!this.field.value?.data.surname;
    this.setError(isValid);
    return isValid;
  }
}

export function fullName(params: FullNameProps): [typeof FullNameValidator, FullNameProps] {
  return [FullNameValidator, params]
}
