import React, { useCallback, useEffect, useState } from 'react';

import { ThemeProvider } from "@mui/material/styles";
import Box from '@mui/material/Box';
import dadataCss from 'react-dadata/dist/react-dadata.css';

import Results from './Results';
import Sidebar from './Sidebar';
import Step1Form from './Step1Form';
import Step2Form from './Step2Form';
import Step3Form from './Step3Form';
import Step4Form from './Step4Form';
import WidgetStepper from './WidgetStepper';

import { ApplicationBankStatusName, ApplicationBankStatus, createApplication, getApplication } from './api';
import { ApplicationFields, FormContextProvider, getApplicationData, useFormStep1, useFormStep2, useFormStep3, useFormStep4 } from './form';
import { juicyScoreComplete, juicyScoreNext } from './juicy-score';
import { JUICY_SCRIPT_URL } from './parameters';
import theme from './theme';

const POLLING_INTERVAL = 5000;

export type WidgetProps = {
  wmId: string,
  token: string,
  addStylesheet: (url: string) => HTMLLinkElement,
  addScript: (url: string, id: string) => HTMLScriptElement,
};

export default function Widget({ wmId, token, addStylesheet, addScript }: WidgetProps) {
  useEffect(() => {
    const links = [
      addStylesheet('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap'),
      addStylesheet('https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100..900;1,100..900&display=swap'),
      addStylesheet('https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap'),
    ];

    return () => {
      links.forEach(link => link.remove?.());
    };
  }, [addStylesheet]);

  useEffect(() => {
    const juicyScript = addScript(JUICY_SCRIPT_URL, 'financial-widget-juicy-script');

    return () => {
      juicyScript.remove?.();
    };
  }, [addScript]);

  const [activeStep, setActiveStep] = useState(0);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isFormFinished, setIsFormFinished] = useState(false);

  const [applicationId, setApplicationId] = useState<number>(null);
  const [isApplicationProcessed, setIsApplicationProcessed] = useState(false);
  const [banks, setBanks] = useState<ApplicationBankStatus[]>([
    /*{
      // offer_id: 39433,
      bank_id: 1194,
      bank_name: 'МФК «Лайм-Займ»',
      sum: 146000.00,
      status: ApplicationBankStatusName.Success,
      link: 'https://cabinet.lime-zaim.ru/registration/phone-confirmation?token=fE0yFN_CogX7yCQGXpnWKh3srKl48pGTFOBwVUu60yUdxJAvgAvPdDE3xz5ffwkhebfB5ppSagsL1kSQzHGrgDRmDOjaIIFkVhWGe4E1Jp7CNuI8tdQsrH3HvwM_Tgl11nNs3zQ9ZmsIGa_UCMVdl0ieNxTq63-Tguul0mHQ7c7tINl4qTNyi6B26l026-MEWWF6Z8QZGiaCwhtvMM7dvm5EyTigkP251fKwGMHen0ZxXdbCrKB3_T19KW8pZrUpUXCNf4hZFc5BuX-kCRwLTxzG0ALV-eHexA5YJ1LxnVyC456w6wrvWbPvR0npnHNF25Qtt2ZgdI-xrjVUa_vFwg&webmaster_id=f7&utm_source=cityads_api',
    },*/
  ]);

  const handleSubmit1 = useCallback(() => {
    juicyScoreNext();

    setActiveStep(1);
  }, [setActiveStep]);

  const handleReverse2 = useCallback(() => setActiveStep(0), [setActiveStep]);
  const handleSubmit2 = useCallback(() => {
    juicyScoreNext();

    setActiveStep(2);
  }, [setActiveStep]);

  const handleReverse3 = useCallback(() => setActiveStep(1), [setActiveStep]);
  const handleSubmit3 = useCallback(() => {
    juicyScoreNext();

    setActiveStep(3);
  }, [setActiveStep]);

  const handleReverse4 = useCallback(() => setActiveStep(2), [setActiveStep]);
  const handleSubmit4 = useCallback(async () => {
    juicyScoreComplete();

    setIsFormSubmitting(true);

    let applicationId = null;

    try {
      applicationId = await createApplication(
        { wmId, token },
        await getApplicationData({
          ...formStep1[0].getFormData(),
          ...formStep2[0].getFormData(),
          ...formStep3[0].getFormData(),
          ...formStep4[0].getFormData(),
        } as ApplicationFields)
      );
    } catch {
      setApplicationId(null);
      setIsFormFinished(true);
      setIsApplicationProcessed(true);
      setBanks([]);

      return;
    } finally {
      setIsFormSubmitting(false);
    }

    setApplicationId(applicationId);
    setIsFormFinished(true);

    let applicationStatus = null;
    let isProcessing = false;

    do {
      if (applicationStatus) {
        await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
      }

      applicationStatus = await getApplication({ wmId, token }, applicationId);

      const isProcessingBanks = applicationStatus.banks.some(({ status }) => status === ApplicationBankStatusName.Processing);
      isProcessing = !applicationStatus.processed || isProcessingBanks;

      setIsApplicationProcessed(!isProcessing);
      setBanks(applicationStatus.banks);
    } while (isProcessing);
  }, [wmId, setIsFormSubmitting, setIsFormFinished, setApplicationId, setIsApplicationProcessed, setBanks]);

  const formStep1 = useFormStep1(handleSubmit1);
  const formStep2 = useFormStep2(handleSubmit2, handleReverse2);
  const formStep3 = useFormStep3(handleSubmit3, handleReverse3);
  const formStep4 = useFormStep4(handleSubmit4, handleReverse4);

  const stepper = (
    <WidgetStepper activeStep={activeStep} />
  );

  return (
    <>
      <style>{dadataCss}</style>
      <ThemeProvider theme={theme}>
        <FormContextProvider value={[ formStep1, formStep2, formStep3, formStep4 ]}>
          <Box
            sx = {{
              containerType: 'inline-size',
              fontFamily: 'fontFamily',
              fontSize: 'fontSize',
              color: 'text.primary',
              backgroundColor: 'background.default',
            }}
          >
            {isFormFinished ?
              (
                <Box
                  component = "article"
                  sx = {(theme) => ({
                    px: 0,
                    py: 2.5,
                    [theme.containerQueries.up('sm')]: {
                      px: 2,
                      py: 2,
                    },
                    [theme.containerQueries.up('md')]: {
                      px: 6,
                    },
                    [theme.containerQueries.up('lg')]: {
                      px: 12.25,
                    },
                  })}
                >
                  <Results
                    requestedAmount = {formStep1[0].fields.amount.value}
                    fio = {formStep1[0].fields.fullName.value?.data}
                    processed = {isApplicationProcessed}
                    banks = {banks}
                  />
                </Box>
              )
              :
              (
                <Box
                  sx = {(theme) => ({
                    px: 0,
                    py: 1,
                    display: 'grid',
                    gridTemplateAreas: '"widget" "sidebar"',
                    gridTemplateColumns: '100%',
                    [theme.containerQueries.up('sm')]: {
                      px: 2,
                      gridTemplateAreas: '"widget sidebar"',
                      gridTemplateColumns: '70% 30%',
                    },
                    [theme.containerQueries.up('md')]: {
                      px: 6,
                    },
                    [theme.containerQueries.up('lg')]: {
                      px: 12.25,
                    },
                  })}
                >
                  <Box
                    component = "article"
                    sx = {{
                      gridArea: 'widget',
                      py: 1,
                    }}
                  >
                    {(activeStep === 0) && (
                      <Step1Form
                        stepper = {stepper}
                      />
                    )}

                    {(activeStep === 1) && (
                      <Step2Form
                        stepper = {stepper}
                      />
                    )}

                    {(activeStep === 2) && (
                      <Step3Form
                        stepper = {stepper}
                      />
                    )}

                    {(activeStep === 3) && (
                      <Step4Form
                        stepper = {stepper}
                        disabled = {isFormSubmitting}
                      />
                    )}
                  </Box>

                  <Box
                    component = "aside"
                    sx = {(theme) => ({
                      gridArea: 'sidebar',
                      position: 'sticky',
                      zIndex: 'drawer',
                      bottom: 0,
                      borderTop: '1px solid #E4E4E7',
                      borderRadius: 3,
                      backgroundColor: 'background.default',
                      pt: 2.5,
                      pr: 2,
                      pb: 2,
                      pl: 2,
                      [theme.containerQueries.up('sm')]: {
                        position: 'static',
                        bottom: 'auto',
                        borderTop: 0,
                        borderRadius: 0,
                        pt: 0,
                        pr: 0,
                        pb: 0,
                      },
                    })}
                  >
                    <Sidebar
                      step = {activeStep}
                      sx = {(theme) => ({
                        position: 'static',
                        top: 'auto',
                        [theme.containerQueries.up('sm')]: {
                          position: 'sticky',
                          top: '8px',
                        },
                      })}
                    />
                  </Box>
                </Box>
              )
            }
          </Box>
        </FormContextProvider>
      </ThemeProvider>
    </>
  );
}
