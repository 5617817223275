import React, { useMemo  } from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import {
  createTheme,
  ThemeProvider
} from "@mui/material/styles";

import { BaseUrlContextProvider } from './base-url';
import Widget, { WidgetProps } from './Widget';

export type WidgetWrapperProps = Omit<WidgetProps, 'addStylesheet'> & {
  shadowRoot: ShadowRoot,
  shadowRootContainer: Element,
  addExternalStylesheet: WidgetProps['addStylesheet'],
  src: string,
}

export default function WidgetWrapper({ shadowRoot, shadowRootContainer, addExternalStylesheet, src, ...props }: WidgetWrapperProps) {
  const shadowCache = useMemo(() => createCache({
    key: 'css',
    prepend: true,
    container: shadowRoot,
  }), [shadowRoot]);

  const shadowTheme = useMemo(() => createTheme({
    components: {
      MuiPopover: {
        defaultProps: {
          container: shadowRootContainer
        }
      },
      MuiPopper: {
        defaultProps: {
          container: shadowRootContainer
        }
      },
      MuiModal: {
        defaultProps: {
          container: shadowRootContainer
        }
      },
    },
  }), [shadowRootContainer]);

  const baseUrl = useMemo(() => {
    const parts = src.split('/');
    parts.pop();
    return parts.join('/') + '/';
  }, [src]);

  return (
    <CacheProvider value={shadowCache}>
      <ThemeProvider theme={shadowTheme}>
        <BaseUrlContextProvider value={baseUrl}>
          <Widget {...props} addStylesheet={addExternalStylesheet} />
        </BaseUrlContextProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
