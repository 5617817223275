import React from 'react';

import Slider, { SliderProps } from '@mui/material/Slider';

import AligningMarkLabel from './AligningMarkLabel';

import { formatNumber } from './utils/intl';

import { MIN_AMOUNT, MAX_AMOUNT } from './parameters';

const amountMarks = [MIN_AMOUNT, MAX_AMOUNT / 3, MAX_AMOUNT / 3 * 2, MAX_AMOUNT].map((displayValue/*, index, array*/) => ({
  value: displayValue, // minAmount + (maxAmount - minAmount) / (array.length - 1) * index,
  displayValue,
  label: formatNumber(displayValue, { style: 'currency', maximumFractionDigits: 0 }),
}));

// function scaleAmount(value: number): number {
//   if (value <= amountMarks[0].value) return amountMarks[0].displayValue;

//   for (let i = 0; i < amountMarks.length - 1; i++) {
//     if (value > amountMarks[i].value && value <= amountMarks[i + 1].value) {
//       return amountMarks[i].displayValue +
//         (value - amountMarks[i].value) / (amountMarks[i + 1].value - amountMarks[i].value) * (amountMarks[i + 1].displayValue - amountMarks[i].displayValue);
//     }
//   }

//   return amountMarks[amountMarks.length - 1].displayValue;
// }

export type AmountSliderProps = SliderProps;

export default function AmountSlider(props: AmountSliderProps) {
  return (
    <Slider
      min = {MIN_AMOUNT}
      max = {MAX_AMOUNT}
      // scale = {scaleAmount}
      marks = {amountMarks}
      slots = {{
        markLabel: AligningMarkLabel,
      }}
      {...props}
    />
  );
}
