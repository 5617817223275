import React from 'react';

import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { PartySuggestionsField } from './DaDataTextField';
import { PhoneTextField } from './MaskedTextField';
import StepForm, { StepFormProps, BackButton } from './StepForm';

import { useForms } from './form';
import { getGridWidth } from './theme';

export type Step3FormProps = Omit<StepFormProps, 'header' | 'children' | 'footer' | 'onSubmit'>;

export default function Step3Form({ stepper, ...props }: Step3FormProps) {
  const [, , [form, setWorkPhone, handleSubmit, handleReverse]] = useForms();

  return (
    <StepForm
      header = "Заполните данные о работе"
      stepper = {stepper}
      footer = {
        <BackButton onClick={handleReverse} />
      }
      onSubmit = {handleSubmit}
      {...props}
    >
      <Grid container columnSpacing={2} rowSpacing={2} sx={{ mt: 0.5, mb: 2.5 }}>
        <Grid size={12}>
          <TextField
            select
            label = "Выберите тип занятости*"
            variant = "filled"
            fullWidth
            autoFocus
            id = "job-type"
            name = "job-type"
            value = {form.fields.jobType.value}
            onChange = {form.fields.jobType.handleChange}
            error = {form.fields.jobType.error}
            helperText = {form.fields.jobType.errorText}
          >
            <MenuItem value="employed">
              Работаю по найму
            </MenuItem>
            <MenuItem value="selfEmployed">
              Работаю на себя
            </MenuItem>
            <MenuItem value="unemployed">
              Не работаю
            </MenuItem>
          </TextField>
        </Grid>
        {(form.fields.jobType.value !== 'unemployed') && (
          <>
            <Grid size={12}>
              <PartySuggestionsField
                label = "Название организации*"
                fullWidth
                id = "organization-name"
                name = "organization-name"
                value = {form.fields.organizationName.value}
                onChange = {form.fields.organizationName.setValue}
                error = {form.fields.organizationName.error}
                helperText = {form.fields.organizationName.errorText}
              />
            </Grid>
            <Grid size={12}>
              <PhoneTextField
                label = "Рабочий телефон*"
                variant = "filled"
                fullWidth
                id = "work-phone"
                name = "work-phone"
                value = {form.fields.workPhone.value}
                onAccept = {setWorkPhone}
                error = {form.fields.workPhone.error}
                helperText = {form.fields.workPhone.errorText}
                InputLabelProps = {{ shrink: true }}
              />
            </Grid>
            <Grid
              size = {12}
              sx = {(theme) => ({
                width: '100%',
                [theme.containerQueries.up('sm')]: {
                  width: getGridWidth(6),
                },
              })}
            >
              <TextField
                label = "Название должности*"
                variant = "filled"
                fullWidth
                id = "work-position-name"
                name = "work-position-name"
                value = {form.fields.workPositionName.value}
                onChange = {form.fields.workPositionName.handleChange}
                error = {form.fields.workPositionName.error}
                helperText = {form.fields.workPositionName.errorText}
              />
            </Grid>
            <Grid
              size = {12}
              sx = {(theme) => ({
                width: '100%',
                [theme.containerQueries.up('sm')]: {
                  width: getGridWidth(6),
                },
              })}
            >
              <TextField
                select
                label = "Тип должности*"
                variant = "filled"
                fullWidth
                id = "work-position-name"
                name = "work-position-name"
                value = {form.fields.workPositionType.value}
                onChange = {form.fields.workPositionType.handleChange}
                error = {form.fields.workPositionType.error}
                helperText = {form.fields.workPositionType.errorText}
              >
                <MenuItem value="ceo">
                  Генеральный директор
                </MenuItem>
                <MenuItem value="manager">
                  Руководитель
                </MenuItem>
                <MenuItem value="specialist">
                  Специалист
                </MenuItem>
                <MenuItem value="worker">
                  Рабочий
                </MenuItem>
                <MenuItem value="servicePersonnel">
                  Обслуживающий персонал
                </MenuItem>
              </TextField>
            </Grid>
          </>
        )}
      </Grid>
    </StepForm>
  );
}
