// import reportWebVitals from './reportWebVitals';
import WidgetWebComponent from './WidgetWebComponent';

import { kebabCase } from './utils/string';

function addContainerForScript(script: HTMLScriptElement): HTMLElement {
  const element = document.createElement('ca-financial-widget');

  Object.keys(currentScript.dataset).forEach(key => {
    element.setAttribute(kebabCase(key), currentScript.dataset[key]);
  });
  element.setAttribute('src', currentScript.src);

  script.parentNode?.insertBefore(element, script.nextSibling);

  return element;
}


if (!customElements.get('ca-financial-widget')) {
  customElements.define('ca-financial-widget', WidgetWebComponent);
}

const currentScript = document.currentScript as HTMLScriptElement;

addContainerForScript(currentScript);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
