import { FIRANO_URL } from './parameters';

type ApiOptions = {
  url: string,
  method: 'get' | 'post',
}

type ApiParams = {
  wmId: string,
  token: string,
}

type ErrorResponse = {
  error: {
    code: number,
    errors: {
      [key: string]: string[],
    },
    message: string,
  },
}

export async function makeApiRequest<RequestType extends object, ResponseType extends object>(
  { url, method }: ApiOptions,
  { wmId, token }: ApiParams,
  data?: RequestType
): Promise<ResponseType> {
  const fetchParams: Parameters<typeof fetch>[1] = {
    method,
  };

  if (method === 'post') {
    fetchParams.headers = {
      'Content-Type': 'application/json',
    };
    fetchParams.body = JSON.stringify(data);
  }

  const fetchResult = await fetch(`${url}?wm_id=${wmId}&token=${token}`, fetchParams);

  if (fetchResult.status >= 400 && fetchResult.status < 500) {
    const errorResponse = await fetchResult.json() as ErrorResponse;

    throw new Error(errorResponse.error.message);
  }

  const response = await fetchResult.json() as ResponseType;

  return response;
}

export type ApplicationDataAddress = {
  country_code?: string,
  index?: string,
  region?: string,
  area?: string,
  city?: string,
  street?: string,
  housing?: string,
  building?: string,
  house?: string,
  flat?: string,
  start_date?: string,
  okato?: string,
  oktmo?: string,
  kladr?: string,
  fias?: string,
}

export enum ApplicationDataGender {
  Male = 'm',
  Female = 'f',
}

export enum ApplicationDataSocialStatus {
  Working = 'working',
  Unemployed = 'unemployed',
  Retired = 'retired',
  WorkingLessThan3Months = 'working_less_3_months',
  WorkingInformally = 'working_informally',
  Businessman = 'businessman',
}

export enum ApplicationDataWorkStatus {
  Working = 10,
  WorkingWithWorkerBook = 11,
  WorkingOnContract = 12,
  Unemployed = 20,
  Pensioner = 30,
  WorkingInformally = 40,
  Businessman = 50,
  WorkingLessThan1Months = 60,
  Working1To3Months = 70,
  Working3To6Months = 80,
  Working6To12Months = 90,
  Working1To3Years = 100,
  Working3To5Years = 110,
  Working5To10Years = 120,
  WorkingOver10Years = 130,
  Military = 140,
  Housewife = 150,
  Student = 160,
  WorkingPensioner = 170,
}

export enum ApplicationDataWorkPositionType {
  TopManager = 1,
  Administrator = 2,
  MilitaryContract = 4,
  MiddleManager = 6,
  Specialist = 7,
}

export type ApplicationData = {
  is_agree_personal: boolean,
  mobile: string,
  surname: string,
  name: string,
  patronymic?: string,
  birth_date?: string,
  birth_place?: string,
  gender?: ApplicationDataGender,
  phone?: string,
  income_personal?: number,
  amount?: number,
  term?: number,
  term_day?: number,
  email?: string,
  work_name?: string,
  work_position?: string,
  work_phone?: string,
  country?: string,
  social_status?: ApplicationDataSocialStatus,
  work_status?: ApplicationDataWorkStatus,
  work_position_type?: ApplicationDataWorkPositionType,
  company_name?: string,
  address_reg?: ApplicationDataAddress,
  address_live?: ApplicationDataAddress,
  address_work?: ApplicationDataAddress,
  passport?: {
    issue_by?: string,
    issue_code?: string,
    issue_date?: string,
    number?: string,
    series?: string,
  },
  user_agent?: string,
  juicy_session_id?: string,
}

type ApplicationCreationResponse = {
  application: {
    id: number,
  },
}

export async function createApplication(params: ApiParams, data: ApplicationData): Promise<number> {
  const response = await makeApiRequest<ApplicationData, ApplicationCreationResponse>(
    {
      url: FIRANO_URL + '/api/rest/v2/widget_applications.json',
      method: 'post',
    },
    params,
    data
  );

  return response.application.id;
}

export enum ApplicationBankStatusName {
  Processing = 'processing',
  Success = 'success',
  Failure = 'failure',
}

export type ApplicationBankStatus = {
  bank_id: number,
  bank_name: string,
  sum: number,
  status: ApplicationBankStatusName,
  link: string,
}

type ApplicationStatusResponse = {
  processed: boolean,
  banks: ApplicationBankStatus[],
}

export async function getApplication(params: ApiParams, id: number): Promise<ApplicationStatusResponse> {
  const response = await makeApiRequest<never, ApplicationStatusResponse>(
    {
      url: FIRANO_URL + `/api/rest/v2/widget_applications/${id}.json`,
      method: 'get',
    },
    params
  );

  return {
    ...response,
    banks: response.banks.map(bankStatus => ({
      ...bankStatus,
      sum: Number(bankStatus.sum),
    })),
  };
}
