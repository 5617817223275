import { DefaultValidatorProps, Validator, ValidatorProps  } from '@xvii/useform/dist/Validator';

type ProhibitedValueProps<T> = DefaultValidatorProps & {
  value: T,
}

export class ProhibitedValueValidator<T> extends Validator<T> {
  prohibitedValue: T;

  constructor(props: ValidatorProps<T, ProhibitedValueProps<T>>) {
    super(props);
  
    this.prohibitedValue = props.params.value;
  }

  validate(): boolean {
    const isValid = this.field.value !== this.prohibitedValue;
    this.setError(isValid);
    return isValid;
  }
}

export function prohibitedValue<T>(params: ProhibitedValueProps<T>): [typeof ProhibitedValueValidator<T>, ProhibitedValueProps<T>] {
  return [ProhibitedValueValidator, params]
}
